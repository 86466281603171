$(function() {
	$('.hero-home').slick({
		infinite: true,
		speed: 400,
		fade: true,
		autoplay: true,
		autoplaySpeed: 4000
	});

	$(window).scroll(function () {
	        // if ($(document).scrollTop() > 10 && $(window).width() >= 1943) {
	        	if ($(document).scrollTop() > 10 && $(window).width() >= 1280) {
	            $(".hero-home .hero-image .container").addClass("scrolled");
	            console.log('scrolled');
	        } else {
	            $(".hero-home .hero-image .container").removeClass("scrolled");
	            }       
	    });


	$('.testimonial-entry').slick({
		infinite: true,
		autoplay: true,
		autoplaySpeed: 8000,
		speed: 800,
		slidesToShow: 1,
		adaptiveHeight: true
	});

	$('.menu-toggle').click(function(){
			$(this).toggleClass('open');
		});
});
